import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text } from "../../components/Core";
import { device } from "../../utils";

const ImgContainer = styled.div`
  margin-top: 50px;
  @media ${device.lg} {
    margin-top: 0px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(55%, -50%);
  }
  @media ${device.xl} {
    transform: translate(40%, -50%);
  }
  @media (min-width: 1400px) {
    transform: translate(15%, -50%);
  }
`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 15px;
  padding-left: 0;

  @media ${device.sm} {
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: space-between;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 9px;
      margin-right: 13px;
    }
  }
`;

const Content1 = () => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section
      bg="#F7F7FB"
      className="position-relative"
      py={["50px", null, "130px", null, "230px"]}
    >
      <Container>
        <Row className="align-items-center">
          <Col lg="6" className=" position-static order-lg-2">
            <ImgContainer className=" pl-lg-5">

            </ImgContainer>
          </Col>
          <Col lg="10" className="order-lg-1 mt-5 mt-lg-0">
            <div
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div>
                <Title>
                  Features of Restaurant Maintenance Management Software
                </Title>
                <Text mb={5}>
                  You’re in the middle of managing a really busy evening at the
                  restaurant, when one of your main ovens start blowing an
                  unwelcome cloud of smoke. What do you do? What – or who – was
                  responsible? How on Earth will you get through the evening
                  without your #1 source of food production?
                  <br className="d-none d-md-block" />
                  The likely cause may be a lack of maintenance which,
                  considering your workload, you entirely forgot to schedule.
                  The solution? A software system that lets you prevent
                  equipment breakdowns long before they have a chance to
                  malfunction
                </Text>
                <Text color="secondary">
                  With restaurant maintenance management software, you can:
                </Text>

                <ULStyled>
                  <li>Track kitchen equipment repairs and maintenance</li>
                  <li>Comply with health and safety standards</li>
                  <li>Maintain bathroom health and safety standards</li>
                  <li>Track KPIs and increase productivity accordingly</li>
                  <li>Make well-informed, data-driven decisions</li>
                  <li>
                    Improve productivity, save on costs, and maximize time
                    schedules
                  </li>
                </ULStyled>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content1;
